<template>
    <CModal
      :title="title"
      :color="color"
      :show.sync="currencyModal"
    >   
        <CForm>
            <CRow>
              <CCol :md="$v.form.code.$model == 'MXN' ? 12 : 8">
                <CSelect
                  label="Moneda:"
                  :isValid="checkIfValid('code')"
                  :value.sync="$v.form.code.$model"
                  :options="currenciesOptions"
                  invalidFeedback="Este campo es requerido."
                  :disabled="$v.form.id.$model > 0"
                  @change="changeCurrency"
                />
              </CCol>
              <CCol md="4" v-if="$v.form.code.$model != 'MXN'">
                <CInput
                    label="TC:"
                    :lazy="false"
                    :isValid="checkIfValid('exchange_rate')"
                    :value.sync="$v.form.exchange_rate.$model"
                    placeholder=""
                    invalidFeedback="Este campo es requerido."
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12">
                <CInput
                  label="Nombre:"
                  :lazy="false"
                  :value.sync="$v.form.name.$model"
                  :isValid="checkIfValid('name')"
                  placeholder=""
                  autocomplete="name"
                  invalidFeedback="Este campo es requerido y debe ser mayor a 2 caracteres."
              />
              </CCol>
            </CRow>
        </CForm>
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cancelar</CButton>
          <CButton @click="store" :color="color">Aceptar</CButton>
        </template>
    </CModal>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minLength, maxLength, integer, email} from "vuelidate/lib/validators"
import store from '../../../store'

import currencies_ws from '../../../services/currencies';

export default { 
  name: 'CurrencyModal',
  props: {

  },
  data () {
    return {
      currencyModal: false,
      title: "Nueva Moneda", 
      color: "info",
      isEdit: false,
      form: this.getEmptyForm(),
      submitted: false,
      currencies: [],
      currenciesOptions: []
    }
  },
  mounted: async function() {
    let response1 = await currencies_ws.getGlobal(); 

    if(response1.type == "success"){
      this.currencies = response1.data;
    }

    this.currenciesOptions = await this.$parseSelectOptionsOrdered(this.currencies, "code", "code");
  },
  methods: {
    async storeModal (items) {
      this.currencyModal = true;
      this.color = "info";
      this.title = 'Nueva Moneda';
      this.isEdit = false;
      this.submitted = false;

      this.currenciesOptions = await this.$discardOptions(this.currenciesOptions, items,"value:code");
    
      this.form = this.getEmptyForm();

      if(this.currenciesOptions[0]){
        this.form.code = this.currenciesOptions[0].label;
        let currency = await this.$findElementByCode(this.currencies, this.form.code);

        this.form.code = currency.code;
        this.form.name = currency.name;
      }
      else{
        this.showToast("warning", "No existen más monedas disponibles.");
        this.closeModal();
      }
    },
    async changeCurrency () {
      let currency = await this.$findElementByCode(this.currencies, this.form.code);

      this.form.code = currency.code;
      this.form.name = currency.name;
    },
    async updateModal (item) {
      this.currencyModal = true;
      this.color = "warning";
      this.title = 'Editar Moneda';
      this.isEdit = true;
      this.submitted = false;

      this.currenciesOptions = await this.$parseSelectOptionsOrdered(this.currencies, "code", "code");

      this.form = {
          id: item.id,
          code: item.code,
          name: item.name,
          exchange_rate: item.exchange_rate
      };
    },
    store () {
      this.$v.$touch();

      if (this.isValid) {
        this.submitted = true;

        if(this.form.id > 0){
          this.$emit("update", this.form );
        }
        else{
          this.$emit("store", this.form );
        }

        this.closeModal();
      }
    },
    closeModal () {
      this.currencyModal = false;

      this.form = this.getEmptyForm()
      this.submitted = false
      this.$v.$reset()
    },
    getEmptyForm () {
      return {
          id: 0,
          code: '',
          name: '',
          exchange_rate: ''
      }
    },
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      
      return !(field.$invalid)
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  },
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      id: false,
      code: {
        required,
        minLength: minLength(3)
      },
      name: {
        required,
        minLength: minLength(3)
      },
      exchange_rate: {
        required
      }
    }
  }
}
</script>